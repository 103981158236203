import React, { Component } from 'react';
import Juicer from './CustomJuicer';
import '../styles/SocialFeed.scss';

export default class SocialFeed extends Component {
	render() {
		return (
			<section className="SocialFeed">
				<div className="container top">
					<div className="columns">
						<div className="column">
							<div className="inner">
								<h2>Tell us why you support</h2>
								<h2>the new Barrie YMCA using</h2>
								<h2 className="big">#100ReasonsY</h2>
							</div>
						</div>
						<div className="column">
							<div className="inner">
								<a href="https://twitter.com/YMCASM">
									<img
										className="social-icon"
										src="/img/Social_Twitter.svg"
										alt="Twitter Logo"
									/>
								</a>
								<a href="https://www.instagram.com/ymcasm/">
									<img
										className="social-icon"
										src="/img/Social_Insta.svg"
										alt="Instagram Logo"
									/>
								</a>
								<a href="https://www.facebook.com/YMCAofSimcoeMuskoka/">
									<img
										className="social-icon"
										src="/img/Social_Facebook-01.svg"
										alt="Facebook Logo"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<Juicer feedId="ymcaofsimcoemuskoka" limit={25} />
				</div>
			</section>
		);
	}
}
