import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export default (props) => {
	let posClass = '';

	// keeps props.current value between 0 and (props.length - 1)
	let norm = (x) => {
		while (x < 0) x += props.length;
		return x % props.length;
	};

	// calculate # of slides to keep on each side of center
	let lSlides = parseInt((props.length - 3) / 2);
	let rSlides = props.length - 3 - lSlides;

	// add 1 to have the proper boundary
	let lBoundary = lSlides + 1;
	let rBoundary = rSlides + 1;

	// slides left of current
	// 		checks which boundary is bigger to ensure the proper sign
	if (norm(props.current - 1) < norm(props.current - lBoundary)) {
		if (
			props.index < norm(props.current - 1) ||
			props.index >= norm(props.current - lBoundary)
		) {
			posClass = 'left-off';
		}
	} else {
		if (
			props.index < norm(props.current - 1) &&
			props.index >= norm(props.current - lBoundary)
		) {
			posClass = 'left-off';
		}
	}

	// slide directly left
	if (props.index === norm(props.current - 1)) {
		posClass = 'left';
	}
	// slide direct right
	else if (props.index === norm(props.current + 1)) {
		posClass = 'right';
	}

	// 5 slides right of current
	// 		checks which boundary is bigger to ensure the proper sign
	if (norm(props.current + 1) > norm(props.current + rBoundary)) {
		if (
			props.index > norm(props.current + 1) ||
			props.index <= norm(props.current + rBoundary)
		) {
			posClass = 'right-off';
		}
	} else {
		if (
			props.index > norm(props.current + 1) &&
			props.index <= norm(props.current + rBoundary)
		) {
			posClass = 'right-off';
		}
	}

	return (
		<div className={`reason-card ${posClass} `}>
			<div className="face-wrap">
				<LazyLoadImage
					className="face is-hidden-tablet"
					src={
						props.data.imgPortrait || '/img/YMCA_100Reasons_Craig_Mobile.jpg'
					}
					alt="Face"
					effect="opacity"
				/>
				<LazyLoadImage
					className="face is-hidden-mobile"
					src={props.data.imgLandscape || '/img/YMCA_100Reasons_Craig.jpg'}
					alt="Face"
					effect="opacity"
				/>
				<span className="reason-number">REASON {props.data.number}</span>
			</div>
			<div className="content">
				<h3>{props.data.heading}</h3>
				<p>{props.data.quote}</p>
				<div className="attribution">
					<h4>{props.data.name}</h4>
					<h4>{props.data.job}</h4>
				</div>
			</div>
		</div>
	);
};
