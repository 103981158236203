import React from 'react';
import '../styles/BlogPreview.scss';

export default (props) => {
	return (
		<div className="BlogPreview">
			<div className="img-wrap">
				<img src={props.img} alt="Blog Thumbnail" />
			</div>
			<div className="content-wrap">
				<h4>{props.date}</h4>
				<h3 className="reg-heading small">{props.title}</h3>
				<p>{props.snippet}</p>
				<a href={props.name}>
					<button>READ MORE &rarr;</button>
				</a>
			</div>
		</div>
	);
};
