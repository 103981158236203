import React from 'react';
import { graphql } from 'gatsby';

import HomeTemplate from '../templates/HomeTemplate';
import ModalTemplate from '../templates/ModalTemplate.js';
import TempModalTemplate from '../templates/TempModalTemplate';

const HomePage = ({ data }) => {
	return (
		<>
			<TempModalTemplate />
			<HomeTemplate data={data} />
		</>
	);
};

export default HomePage;

export const query = graphql`
	{
		updates: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/updates/" } }
			limit: 2
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						author
						date(formatString: "MMMM DD, YYYY")
						thumbnail
					}
					excerpt
				}
			}
		}
		amount: allMarkdownRemark(
			filter: { frontmatter: { slug: { eq: "amount-raised" } } }
		) {
			edges {
				node {
					frontmatter {
						amount
					}
				}
			}
		}
	}
`;
