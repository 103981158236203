import React from 'react';
import BlogPreview from '../components/BlogPreview';
import '../styles/Updates.scss';

export default (props) => {
	return (
		<div className="Updates is-hidden-mobile">
			<div className="container">
				<h2 className="reg-heading has-text-centered">Project Updates</h2>
				<div className="columns previews">
					{props.data.updates.edges.map((item, index) => (
						<div className="column" key={index}>
							<BlogPreview
								img={item.node.frontmatter.thumbnail}
								title={item.node.frontmatter.title}
								date={item.node.frontmatter.date}
								snippet={item.node.excerpt}
								name={item.node.fields.slug}
							/>
						</div>
					))}
				</div>
				<div className="has-text-centered all">
					<a href="/updates">
						<button className="y-button blue">VIEW ALL</button>
					</a>
				</div>
			</div>
		</div>
	);
};
