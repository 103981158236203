import React from 'react';

import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Opener from '../components/Opener';
import Reasons from '../components/Reasons';
import VideoSection from '../components/VideoSection';
import FeaturesServices from '../components/FeaturesServices';
import Timeline from '../components/Timeline';
import Updates from '../components/Updates';
import Donate from '../components/Donate';
import SocialFeed from '../components/SocialFeed';
import ConnectSection from '../components/ConnectSection';
import Footer from '../components/Footer';
import Partners from '../components/Partners';

const HomeTemplate = (props) => {
	return (
		<Layout>
			<Navbar />
			<Hero />
			<Opener />
			<Reasons />
			{/* <VideoSection /> */}
			<FeaturesServices />
			{/* <Timeline /> */}
			<Updates data={props.data} />
			<Donate data={props.data} />
			<SocialFeed />
			<ConnectSection />
			<Partners />
			<Footer />
		</Layout>
	);
};

export default HomeTemplate;
