import React, { useState, useEffect } from 'react';
import '../styles/Hero.scss';

const images = [
	'/img/YMCA_100Reasons_MH.jpg',
	'/img/YMCA_100Reasons_MH2.jpg',
	'/img/YMCA_100Reasons_MH3.jpg',
];

export default () => {
	const [slideIndex, setSlideIndex] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			if (slideIndex < images.length - 1) {
				setSlideIndex(slideIndex + 1);
			} else {
				setSlideIndex(0);
			}
		}, 4000);
		return () => {
			clearInterval(interval);
		};
	});
	return (
		<div className="Hero">
			{images.map((url, index) => (
				<div
					className={`background${index === slideIndex ? '' : ' faded'}`}
					style={{ backgroundImage: `url(${url})` }}
					key={index}
				/>
			))}
			<div className="foreground">
				<div className="inner">
					<h2>THERE ARE 100 REASONS TO GIVE. WHAT'S YOUR REASON?</h2>
					<a href="/#new-y">
						<button className="y-button orange">LEARN MORE</button>
					</a>
					<a href="/donate">
						<button className="y-button orange">DONATE NOW</button>
					</a>
				</div>
			</div>
		</div>
	);
};
