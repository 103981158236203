import React, { Component } from 'react';
import Loadable from '@loadable/component';
import '../styles/VideoSection.scss';

const LoadableModalVideo = Loadable(() => import('react-modal-video'));

export default class VideoSection extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.openModal = this.openModal.bind(this);
	}

	openModal() {
		this.setState({ isOpen: true });
	}

	render() {
		return (
			<section className="VideoSection">
				<div className="content-wrap">
					<div className="inner">
						<div>
							<h2 className="reg-heading">
								We asked three of Barrie’s most respected leaders to share their
								‘Reason Y’
							</h2>
							<div className="button-wrap" onClick={() => this.openModal()}>
								<img src="/img/PlayButton.svg" alt="Play button" />
								<h3>WATCH THE VIDEO</h3>
							</div>
						</div>
					</div>
				</div>
				<LoadableModalVideo
					channel="youtube"
					isOpen={this.state.isOpen}
					videoId="rRnR-DLBvlM"
					onClose={() => this.setState({ isOpen: false })}
				/>
			</section>
		);
	}
}
