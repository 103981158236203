import React, { Component } from 'react';
import ReasonCard from './ReasonCard';
import Swipe from 'react-easy-swipe';
import '../styles/Reasons.scss';

export default class Reasons extends Component {
	constructor(props) {
		super(props);
		this.count = reasonsList.length;
		this.state = {
			current: 0,
			last: null,
		};
	}
	increment = () => {
		this.setState((state) => {
			return {
				current: state.current + 1,
			};
		});
	};
	decrement = () => {
		this.setState((state) => {
			return {
				current: state.current - 1,
			};
		});
	};

	render() {
		return (
			<section className="Reasons">
				<span className="anchor" id="reason" />
				<h2 className="reg-heading has-text-centered">What's your reason?</h2>
				<Swipe onSwipeLeft={this.increment} onSwipeRight={this.decrement}>
					<div className="card-holder">
						{reasonsList.map((item, index) => (
							<ReasonCard
								key={index}
								index={index}
								current={this.state.current}
								data={item}
								length={this.count}
							/>
						))}
					</div>
				</Swipe>
				<div className="button-tray">
					<button className="left" onClick={this.decrement}>
						<img src="/img/Arrow_Left.svg" alt="Left" />
					</button>
					<button className="right" onClick={this.increment}>
						<img src="/img/Arrow_Right.svg" alt="Right" />
					</button>
				</div>
			</section>
		);
	}
}

const reasonsList = [
	{
		number: '1',
		heading: 'Meeting the Needs of our Evolving City',
		quote:
			'"The Y as I see it in the future is going to be able to fill a lot of the holes that we are now experiencing within the community."',
		name: 'Janice,',
		job: 'Former Mayor of Barrie/Honourary Capital Campaign Chair',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_Janice.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Janice_Mobile.png',
	},
	{
		number: '4',
		heading: 'Committing to Lifelong Learning',
		quote:
			'"The Y has given my son the opportunity to develop his social skills in a fun environment - every day we\'re amazed by what he\'s learnt."',
		name: 'Amanda,',
		job: "Nathan's mom",
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0002_Nathan.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0002_Nathan.png',
	},
	/*{
		number: '12',
		heading: 'Removing Barriers to Access',
		quote:
			'"It means the world to us, especially my kids. Everybody talks about community - the YMCA lives it every day."',
		name: 'Dawn,',
		job: 'Y Member/Recipient of YMCA Financial Assistance',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0006_Dawn.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0006_Dawn.png',
	},*/
	{
		number: '16',
		heading: 'Connecting Youth to Employment',
		quote:
			'"I was going through a really rough period of my life, and the Y helped me get back on my feet, find a job, and get the skills I needed to succeed.”',
		name: 'Katrina,',
		job: 'Youth Employment Participant',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_Katrina.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Katrina_Mobile.png',
	},
	{
		number: '19',
		heading: 'Establishing a Sense of Belonging',
		quote:
			'"I don\'t have any family here - this is my family, the members and the staff. My Y is my second home."',
		name: 'Mildred',
		job: 'Former YMCA Immigrant Services Client/YMCA Staff',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0004_Mildred.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0004_Mildred.png',
	},
	{
		number: '23',
		heading: 'Inspiring People to Reach Their Potential',
		quote:
			'"The Y opened their doors to me. It didn’t matter what I did before - my future mattered. It was an opportunity to have a career and a better life."',
		name: 'TJ,',
		job: 'YMCA Youth Employment Participant',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0000_TJ.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0000_TJ.png',
	},
	{
		number: '27',
		heading: 'Instilling Self Confidence',
		quote:
			'"The YMCA swimming program has given Lily the confidence she needed to jump into new experiences, both in and out of the pool."',
		name: 'Amanda,',
		job: "Lily's Mom",
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0005_Lilly.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0005_Lilly.png',
	},
	{
		number: '29',
		heading: 'Creating Community',
		quote:
			'"If I needed anything, the Y was the place I went. As a community member, the Y is always on my mind and is part of me."',
		name: 'Moe,',
		job: 'Former YMCA Immigrant Services Client',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0003_Moe.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0003_Moe.png',
	},
	{
		number: '46',
		heading: 'Sharing our Values',
		quote:
			'"Building a caring and inclusive community is the reason why we need the YMCA"',
		name: 'Jeff Lehman,',
		job: 'Former Mayor of the City of Barrie',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_Jeff.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Jeff_Mobile.png',
	},
	{
		number: '66',
		heading: 'Promoting Lifelong Health',
		quote:
			'"After my stent, I was fearful of doing exercise. Now I feel better than ever because my energy is back, and so is my confidence."',
		name: 'Sudhir,',
		job: 'Graduate of YMCA/RVH Healthy Hearts Program',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0001_Sudhir.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0001_Sudhir.png',
	},
	// {
	// 	number: '68',
	// 	heading: 'Partnering in Downtown Development',
	// 	quote:
	// 		'"The role of the Y in the redevelopment of the downtown core is part of downtown reaching its full potential."',
	// 	name: 'Craig Stevens,',
	// 	job: 'Managing Director, Barrie BIA',
	// 	imgLandscape: '/img/YMCA_100Reasons_StoryPics_0008_Craig.png',

	// 	imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0008_Craig.png',
	// },
	{
		number: '88',
		heading: 'Building Resilient Families',
		quote:
			'"Personally, the Y has helped me and my daughter become more resilient, physically and emotionally.”',
		name: 'Amanda,',
		job: 'Barrie Y Member/YMCA Youth Services Staff',
		imgLandscape: '/img/YMCA_100Reasons_StoryPics_0007_Amanda.png',
		imgPortrait: '/img/YMCA_100Reasons_StoryPics_Mobile_0007_Amanda.png',
	},
];
