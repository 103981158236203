import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Modal from '../components/Modal.js';

const TempModalTemplate = () => {
	const data = useStaticQuery(graphql`
		query UpdatesQuery {
			allMarkdownRemark(
				filter: { fileAbsolutePath: { regex: "/updates/" } }
				sort: { fields: [frontmatter___date], order: DESC }
			) {
				edges {
					node {
						fields {
							slug
						}
					}
				}
			}
		}
	`);

	const latestPost = data.allMarkdownRemark.edges[0];
	const latestPostSlug = latestPost ? latestPost.node?.fields?.slug : null;

	return latestPostSlug ? (
		<Modal>
			<div className="sign-up temp-inner has-text-centered">
				<h2 className="reg-heading temp-heading">
					Stay up to date as we move forward with our new Barrie Y!
				</h2>
				<p>View our latest blog post for a campaign update.</p>
				<a className="y-button orange medium" href={latestPostSlug}>
					BLOG
				</a>
			</div>
		</Modal>
	) : null;
};

export default TempModalTemplate;
