import React from 'react';
import '../styles/Opener.scss';

export default () => {
	return (
		<section className="Opener">
			<span className="anchor" id="new-y" />
			<img
				className="render-right-hump is-hidden-mobile is-hidden-tablet-only"
				src="/img/barriey-blog-desktopheader-nov23.jpg"
				alt="YMCA Rendering"
			/>
			<div className="content-wrap">
				<div className="inner">
					<h1 className="reg-heading">
						With our partners, we are building a new kind of Y for Barrie.
					</h1>
					<p>
						This will be a new kind of Y - a hub that will house traditional Y
						programs while supporting other opportunities for the public, our
						membership, and our community partners. This facility will be a
						centre of community where everyone belongs and thrives.
					</p>
					<p>
						The enormous benefits to individual and community health and
						wellness, and our shared vision for a more prosperous and inclusive
						Barrie, are within reach with the new YMCA. The YMCA is investing
						approximately $55 million in this project, which will generate
						millions of dollars in economic spinoffs, create hundreds of jobs,
						and stimulate the economy.
					</p>
				</div>
			</div>
			<img
				className="render-bottom-hump is-hidden-desktop"
				src="/img/barriey-blog-mobileheader-nov23.jpg"
				alt="YMCA Rendering"
			/>
		</section>
	);
};
