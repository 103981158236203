import React, { Component } from 'react';
import '../styles/Timeline.scss';

const events = [
	{
		src: '/img/100ReasonsY_Timeline_Icon1.svg',
		alt: 'Community Icon',
		title: 'Engage Community in Vision <br/>(2015-16)',
	},
	{
		src: '/img/100ReasonsY_Timeline_Icon2.svg',
		alt: 'Checklist Icon',
		title: 'Capital Campaign Readiness <br/>(2017)',
	},
	{
		src: '/img/100ReasonsY_Timeline_Icon3.svg',
		alt: 'Map Icon',
		title: 'Site Location in Downtown Core <br/>(2018)',
	},
	{
		src: '/img/100ReasonsY_Timeline_Icon4.svg',
		alt: 'Handshake Icon',
		title: 'Launch Capital Campaign <br/>(2019)',
	},
	{
		src: '/img/100Reasons_CovidIcon.svg',
		alt: 'Covid Icon',
		title: ' COVID-19 Pandemic Project Delays <br/>(2020-2022)',
	},
	{
		src: '/img/100Reasons_RelaunchIcon.svg',
		alt: 'Rocket Launch Icon',
		title: 'Relaunch Capital Campaign <br/>(TBD)',
	},
	{
		src: '/img/100ReasonsY_Timeline_Icon5.svg',
		alt: 'Construction Worker Icon',
		title: 'Shovel in Ground <br/>(TBD)',
	},
	{
		src: '/img/100ReasonsY_Timeline_Icon6.svg',
		alt: 'Doors Opening Icon',
		title: 'Open New Y <br/>(TBD)',
	},
];

export default class Timeline extends Component {
	constructor(props) {
		super(props);
		this.count = events.length;
		this.indexComplete = 4;
		this.state = {
			currentIndex: 4,
			title: events[4].title,
		};
	}
	setIndex = (index) => {
		this.setState({
			currentIndex: index,
			title: events[index].title,
		});
	};
	increment = () => {
		this.setState((state) => {
			if (state.currentIndex < this.count - 1) {
				return {
					currentIndex: state.currentIndex + 1,
					title: events[state.currentIndex + 1].title,
				};
			}
		});
	};
	decrement = () => {
		this.setState((state) => {
			if (state.currentIndex > 0) {
				return {
					currentIndex: state.currentIndex - 1,
					title: events[state.currentIndex - 1].title,
				};
			}
		});
	};
	render() {
		return (
			<div className="Timeline is-hidden-mobile">
				<h2 className="reg-heading has-text-centered">Project Timeline</h2>
				<div className="columns is-gapless">
					{events.map((event, index) => (
						<div
							className={`column ${
								index === this.state.currentIndex ? 'active' : ''
							}`}
							onClick={() => {
								this.setIndex(index);
							}}
							key={index}
						>
							<img className="timeline-icon" src={event.src} alt={event.alt} />
							<div className="circle">
								<img
									className={`check ${
										index > this.indexComplete ? 'is-hidden' : ''
									}`}
									src="/img/100ReasonsY_CheckMark.svg"
									alt="Check"
								/>
							</div>
						</div>
					))}
				</div>
				<div className="bottom">
					<div className="heading-wrap">
						<h3
							className="reg-heading"
							key={new Date()}
							dangerouslySetInnerHTML={{ __html: this.state.title }}
						/>
						<div className="button-tray">
							<button onClick={this.decrement}>
								<img src="/img/Arrow_Left.svg" alt="Left" />
							</button>
							<button onClick={this.increment}>
								<img src="/img/Arrow_Right.svg" alt="Right" />
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
