import React from 'react';
import '../styles/FeaturesServices.scss';

export default () => {
	return (
		<section className="FeaturesServices">
			<span className="anchor" id="details" />
			<h2 className="reg-heading has-text-centered">
				We are opening a Centre of Community in 2027 which will include:
			</h2>
			<div className="feature-wrap">
				<div className="columns">
					<div className="column">
						<div className="inner">
							<h2 className="reg-heading">Community Services</h2>
							<ul>
								<li>Shared Space for Child and Youth Programming</li>
								<li>Senior Programming</li>
								<li>Meeting Rooms</li>
								<li>Accessible Indoor Playground</li>
								<li>Youth, Employment and Immigrant Programs</li>
								<li>Child Minding</li>
							</ul>
						</div>
					</div>
					<div className="column">
						<div className="inner">
							<h2 className="reg-heading">Health and Wellness</h2>
							<ul>
								<li>Aquatics Centre</li>
								<li>Individual Conditioning Space</li>
								<li>Gymnasium</li>
								<li>Group Fitness Studios</li>
								<li>Indoor Track</li>
							</ul>
						</div>
					</div>
					<div className="column">
						<div className="inner">
							<h2 className="reg-heading">Partnerships</h2>
							<ul>
								<li>
									Home to Simcoe County's first standalone transitional housing
									unit for youth, in partnership with Youth Haven
								</li>
								<li>
									The YMCA will be home to Royal Victoria Regional Health
									Centre's Cardiovascular Rehabilitation program
								</li>
								<li>
									The YMCA will be delivering Cancer Rehabilitation programs in
									partnership with the Simcoe Muskoka Regional Cancer Centre
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
