import React, { useState, useEffect } from 'react';
import '../styles/Modal.scss';

const Modal = (props) => {
	const [show, setShow] = useState(false);
	const [modalPopped, setModalPopped] = useState(false);
	const [modalStyle, setModalStyle] = useState({
		top: '50px',
		opacity: 0,
		transition: 'all 1s ease',
	});
	const [containerStyle, setContainerStyle] = useState({
		opacity: 0,
		transition: 'all 1s ease',
	});

	useEffect(
		() => {
			if (!modalPopped) {
				//wait 3s to open modal
				setTimeout(() => {
					setShow(true);
					// call the into animation
					setTimeout(() => {
						setModalStyle({
							top: 0,
							opacity: 1,
							transition: 'all 1s ease',
						});
						setContainerStyle({
							opacity: 1,
							transition: 'all 1s ease',
						});
					}, 10);
				}, 3000);
			}
			setModalPopped(true);
		},
		[modalPopped]
	);

	let onCloseModal = () => {
		setTimeout(() => {
			setModalStyle({
				top: '50px',
				opacity: 0,
				transition: 'all 1s ease',
			});
			setContainerStyle({
				opacity: 0,
				transition: 'all 1s ease',
			});
		}, 10);
		//wait for animation to finish
		setTimeout(() => {
			setShow(false);
		}, 900);
	};

	return (
		<>
			{show && (
				<div
					className="modal-container"
					style={containerStyle}
					onClick={() => onCloseModal()}
				>
					<div
						className="form-modal"
						style={modalStyle}
						onClick={(e) => e.stopPropagation()}
					>
						<button className="close" onClick={() => onCloseModal()}>
							<img src="/img/x.svg" alt="x" />
						</button>
						{props.children}
					</div>
				</div>
			)}
		</>
	);
};

export default Modal;
