import React, { Component } from 'react';
import { Link } from 'gatsby';
import '../styles/Donate.scss';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function parseAmount(amt) {
	amt = amt.replace(/,/g, '');
	return parseInt(amt.substr(1));
}

class Donate extends Component {
	state = {
		didViewCountUp: false,
	};

	onVisibilityChange = (isVisible) => {
		if (isVisible) {
			this.setState({ didViewCountUp: true });
		}
	};
	render() {
		return (
			<section className="Donate">
				<span className="anchor" id="donate" />
				<div className="has-text-centered">
					<VisibilitySensor
						onChange={this.onVisibilityChange}
						offset={{
							top: 11,
						}}
						delayedCall
					>
						<h2 className="dollars">
							<CountUp
								duration={2}
								prefix="$"
								separator=","
								end={
									this.state.didViewCountUp
										? parseAmount(
												this.props.data.amount.edges[0].node.frontmatter.amount
										  )
										: 0
								}
							/>
						</h2>
					</VisibilitySensor>
					<h2 className="raised">Raised to date!</h2>
					<Link to="/donate">
						<button className="y-button orange large">DONATE NOW</button>
					</Link>
				</div>
			</section>
		);
	}
}

export default Donate;
